import * as React from "react"
import { graphql } from "gatsby";

import Seo from "../components/shell/seo"
import HomeContent from "../pageContents/home/HomeContent";
import LayoutWrapper from "../template/LayoutWrapper";
import { getSeoData } from "../services/seo.service";

function IndexPage(props) {
  let homeData = props.data.homeJson;

  homeData.news.latestNews = props.data.allNewsJson.nodes;
  homeData.categorys = props.data.allCategoryJson.nodes;

  return <LayoutWrapper pageContext={props.pageContext}>
    <Seo {...getSeoData(props, homeData)} />
    <HomeContent data={homeData} />
  </LayoutWrapper>
}

export default IndexPage;

export const query = graphql`
  {
    homeJson {
      seoTranslations {
        description
        keywords
        language
        title
      }
      pageHeader {
        translations {
          language
          slideshow {
            id
            sort
            largeDeviceImage {
              src {
                publicURL
              }
            }
            smallDeviceImage {
              src {
                publicURL
              }
            }
          }
        }
      }
      news {
        translations {
          description
          language
          title
        }
      }
    }
    allNewsJson {
      nodes {
        date
        id
        sort
        image {
          alt
          src {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        translations {
          language
          summary
          title
        }
      }
    }
    allCategoryJson {
      nodes {
        id
        sort
        productCategoryTitle
        translations {
          language
          title
          description
        }
        image {
          alt
          src {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
      }
    }
  }
`
