import React from "react";

import ALink from "../../../components/merged/Alink";
import Image from "../../../components/merged/Image";
import MarkdownViewer from "../../../components/merged/MarkdownViewer";
import { LargeTitle } from "../../../components/shared/FontCollection";
import "./Category.css";

export default function Category({ title, description, image, reverseView }) {
    return <section className="category">
        <div className="category__content" style={{ flexDirection: reverseView ? "row-reverse" : "row" }}>
            <div className="catergory__info">
                <div className="category__title">
                    <LargeTitle>{title}</LargeTitle>
                </div>
                <div className="catergory__image-hand-device">
                    <Image {...image} className="catergory__image-hand-device-img" />
                </div>
                <div className="category__description">
                    <MarkdownViewer data={description} />
                </div>
                <ALink to="/products">
                    <div className="category__detail-button">
                        <div>View detail</div>
                    </div>
                </ALink>
            </div>
            <div className="catergory__image-desktop">
                <Image {...image} className="catergory__image-desktop-img" />
            </div>
        </div>
    </section>
}