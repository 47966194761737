import React, { useEffect, useRef, useState } from "react";
import AliceCarousel from 'react-alice-carousel';

import Image from "../../../components/merged/Image";
import { LargeTitle, SmallTitle } from "../../../components/shared/FontCollection";
import { normalizeTranslations } from "../../../services/translationHelper.service";
import { useLanguageContext } from "../../../components/shell/LanguageContext";
import MarkdownViewer from "../../../components/merged/MarkdownViewer";
import './News.css';

function NewsCard(newsData) {
    const { language } = useLanguageContext();
    const normalizedData = normalizeTranslations(language, newsData);

    return <div className="card">
        <div className="card__image"><Image {...normalizedData.image} className="card__image-img" /></div>
        <div className="card__title">
            <SmallTitle>{normalizedData.title}</SmallTitle>
        </div>
        <div className="card__summary">
            <MarkdownViewer data={normalizedData.summary.replace(/\n/g, "<br>")} />
        </div>
    </div >
}

function createSlideItems(newsData) {
    if (!newsData) return [];

    return newsData.map((item, key) => {
        return <NewsCard key={key} {...item} />
    });
}

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};

export default function News({ data }) {
    const [carouselEl, setCarouselEl] = useState(null);
    const carouselRef = useRef(null);

    useEffect(() => {
        if (!carouselRef || !carouselRef.current) return;

        setCarouselEl(carouselRef.current);
    }, [carouselRef]);

    return <section className="news">
        <div className="news__content">
            <div className="news__title">
                <LargeTitle>{data.title}</LargeTitle>
            </div>
            <AliceCarousel
                animationType="fadeout"
                infinite
                items={createSlideItems(data.latestNews)}
                responsive={responsive}
                touchTracking
                disableButtonsControls
                disableDotsControls
                disableSlideInfo
                controlsStrategy="alternate"
                ref={carouselRef} />
        </div>
        <div className="news__btn-prev" onClick={() => carouselEl.slidePrev()}>〈</div>
        <div className="news__btn-next" onClick={() => carouselEl.slideNext()}>〉</div>
    </section>
}
