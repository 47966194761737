import React from "react";

import Category from "./sections/Category";
import HomePageHeader from "./sections/HomePageHeader";
import News from "./sections/News";
import { useLanguageContext } from "../../components/shell/LanguageContext";
import { normalizeTranslations } from "../../services/translationHelper.service";

export default function HomeContent({ data }) {
    const languageData = useLanguageContext();

    return <React.Fragment>
        <HomePageHeader {...normalizeTranslations(languageData.language, data.pageHeader)} />
        <News data={normalizeTranslations(languageData.language, data.news)} />
        {
            data.categorys.map((category, key) => {
                return <Category {...normalizeTranslations(languageData.language, category)} reverseView={key % 2 === 1 && true} />
            })
        }
    </React.Fragment>
}