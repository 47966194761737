import React from "react";
import { Slide } from 'react-slideshow-image';
import { getImg } from "../../../components/merged/Image";

import "./HomePageHeader.css";

function SlideItem({ largeDeviceImage, smallDeviceImage }) {
    return <div className="each-slide">
        <div>
            <picture className="each-slide__picture">
                <source srcSet={`${getImg(smallDeviceImage.src)} 1x`} media="(max-aspect-ratio: 3/4)" />
                <source srcSet={`${getImg(largeDeviceImage.src)} 1x`} media="(max-width: 1000px)" />
                <img
                    srcSet={`${getImg(largeDeviceImage.src)} 2x`}
                    alt="Full Logo" />
            </picture>;
        </div>
    </div>
}

export default function HomePageHeader({ slideshow }) {
    return <section className="home-page-header">
        <Slide autoplay={true} indicators={true}>
            {
                slideshow.map((item, index) => {
                    return <SlideItem key={index} {...item} />
                })
            }
        </Slide>
    </section>
}